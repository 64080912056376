<template>
  <div class="flex-column circleMain">
    <pageHead class="messageMainView_head" title="我管理的圈子" />
    <div
      infinite-scroll-throttle-delay="500"
      infinite-scroll-distance="50"
      v-infinite-scroll="loadMore"
      class="main-content">
      <van-skeleton title :row="3" v-if="circleList.length === 0 && !isLoaded" />
      <div v-if="circleList.length > 0">
        <CircleItem
          style="margin-bottom: 24px;"
          v-for="(item,index) in circleList"
          :key="index"
          :item="item"></CircleItem>
      </div>
      <van-empty
        v-if="isLoaded && circleList.length === 0"
        class="custom-image"
        image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
        description="暂无数据"
      />
    </div>

  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
// import hotCircle from '@/components/hotCircle'
import {getListManagedCircle} from "@/api/circle";
import CircleItem from '@/components/circle/circleItem'

export default {
  components: {
    pageHead,
    CircleItem
    // hotCircle
  },
  data() {
    return {
      isLoaded: false,
      circleList: [],
      placeholder: "搜索圈子",
      loading: false,
      finished: false,
      ContSet: {
        title_if: true,
      },
      noDataInfo: {
        title1: '',
        title2: ''
      },
      paginationData: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.getHotList()
  },
  methods: {
    // 加载更多
    loadMore() {
      console.log(111)
      if (!this.finished && this.circleList.length > 0) {
        this.loading = true
        this.paginationData.pageNum++
        this.getHotList()
      }
    },
    // 跳转输入
    toSearch() {
      this.$router.push({
        name: "circleSearch",
      });
    },
    // 点击回调数据
    hotClick(item) {
      this.$router.push({
        path: '/app/circle/circleXq',
        query: {
          id: item.id,
          refreshCircle: true,
        }
      });
    },
    /**
     * todo 获取热门圈子列表
     */
    async getHotList(type) {
      this.loading = true
      try {
        const res = await getListManagedCircle(this.paginationData) || []
        const {records} = res || []
        if (records.length < 10) {
          this.finished = true;
        }
        if (type === 'reset') {
          this.circleList = records
        } else {
          this.circleList = this.circleList.concat(records)
        }
      } finally {
        // 加载状态结束
        this.loading = false;
        this.isLoaded = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.circleMain {
  height: 100%;
  background-color: #FFFFFF;

  .main-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100vh - 52px);
    padding-top: 16px;
    flex: 1;
  }

  .searchBox_box {
    background: #fff;
    padding: 12.34px 0 17.27px;
  }

  .hot_box {
    padding: 18px 15px 10px;
    background: #fff;
  }

  ::v-deep .van-badge--fixed {
    top: 5px;
    right: 2px;
  }
}
</style>
